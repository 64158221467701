import * as React from "react";
import { DatePicker, Form, Input, Radio, Button } from "antd";
import SCButton from "../components/SCButton";
import { editUser } from "../api/users";

const UserEdit = ({ changeMode, mode, suscriptor, setMode }) => {
  const [form] = Form.useForm();
  const [requiredMark, setRequiredMarkType] = React.useState("optional");

  // Cada vez que "suscriptor" cambie, establecemos los valores del formulario.
  React.useEffect(() => {
    if (suscriptor) {
      form.setFieldsValue({
        email: suscriptor.email,
        nombre: suscriptor.nombre,
        apellido: suscriptor.apellido,
        sexo: suscriptor.sexo,
        telefono: suscriptor.telefono,
        // Agrega aquí otros campos que requieras (por ejemplo, estatura, peso, etc.)
      });
    }
  }, [suscriptor, form]);

  const onRequiredTypeChange = ({ requiredMarkValue }) => {
    setRequiredMarkType(requiredMarkValue);
  };

  const onFinish = (values) => {
    // Como el campo "email" está deshabilitado y no se puede editar, forzamos su valor
    values.email = suscriptor.email;
    // Creamos un objeto actualizado combinando el suscriptor actual y los valores del formulario
    const updatedSuscriptor = { ...suscriptor, ...values };
    editUser(updatedSuscriptor.id, updatedSuscriptor).then((response) => {
      if (response) {
        setMode("view");
      }
    });
  };

  return (
    <>
      <Form
        className="w-full flex flex-col"
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onRequiredTypeChange}
        requiredMark={requiredMark}
      >
        <div className="flex  flex-col md:flex-row w-full">
          <div className="flex w-full md:w-1/2 flex-col">
            <h1 className="text-left text-2xl">Datos personales</h1>
            <Form.Item
              label="Correo electrónico"
              name="email"
              tooltip="Este campo es requerido"
            >
              <Input disabled type="email" />
            </Form.Item>
            <Form.Item
              name="nombre"
              label="Nombre"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu nombre",
                },
              ]}
              tooltip="Este campo es requerido"
            >
              <Input placeholder="Nombre" />
            </Form.Item>
            <Form.Item
              name="apellido"
              label="Apellido"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tus apellidos",
                },
              ]}
              tooltip="Este campo es requerido"
            >
              <Input placeholder="Apellido" />
            </Form.Item>
            <Form.Item
              name="sexo"
              label="Sexo"
              rules={[
                {
                  required: true,
                  message: "Por favor, selecciona tu sexo",
                },
              ]}
              tooltip="Este campo es requerido"
            >
              <Radio.Group>
                <Radio value="mujer">Mujer</Radio>
                <Radio value="hombre">Hombre</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="Teléfono"
              name="telefono"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa un número de teléfono válido",
                },
              ]}
              tooltip="Este campo es requerido"
            >
              <Input placeholder="Teléfono" />
            </Form.Item>
          </div>
          <div className="flex w-full md:w-1/2 flex-col md:ml-5">
            <h1 className="text-left text-2xl">Datos de salud</h1>
            <Form.Item
              name="estatura"
              label="Estatura (m)"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu estatura",
                },
              ]}
              tooltip="Este campo es requerido"
            >
              <Input type="number" placeholder="Estatura (m)" />
            </Form.Item>
            <Form.Item
              name="peso"
              label="Peso (kg)"
              rules={[
                {
                  required: true,
                  message: "Por favor, ingresa tu peso",
                },
              ]}
              tooltip="Este campo es requerido"
            >
              <Input type="number" placeholder="Peso" />
            </Form.Item>
          </div>
        </div>
        <div className="m-4 flex justify-end">
          <div className="w-32">
            <Form.Item>
              <Button onClick={changeMode}>Cancelar</Button>
            </Form.Item>
          </div>
          <div className="w-32">
            <Form.Item>
              <Button
                className="rounded-full shadow-2xl p-2 text-sc-titles  cursor-pointer"
                type="primary"
                htmlType="submit"
              >
                {mode === "view" ? "Editar" : "Guardar"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
};

export default UserEdit;
